<script>
import axios from "axios";
import OneTimeAccessTemplate from "../templates/OneTimeAccessTemplate.vue";

export default {
  components: { OneTimeAccessTemplate },
  name: "OneTimeAccessPage",
  data: () => ({
    addressQuery: {
      street: "",
      complement: "",
      neighborhood: "",
      city: "",
      state: "",
    },
    step: 1,
    form: {},
    dialog: {
      birthday: false,
      paymentReference: false,
      paymentDate: false,
      registerDate: false,
    },
    show: true,
    formMode: "new",
  }),
  props: {
    id: {
      type: String,
      default: "",
    },
    hash: String,
  },
  created() {
    this.addressQuery = {
      street: "",
      complement: "",
      neighborhood: "",
      city: "",
      state: "",
    };
  },
  mounted() {
    if (this.oneTime == undefined) {
      this.$store.dispatch(
        "academy/getOneTimeAccess",
        { academyId: this.id, hash: this.hash },
        { root: true }
      );
    } else if (Object.keys(this.oneTime).length == 0) {
      this.$router.push("/one-time-access/link-expired");
    }
  },
  computed: {
    oneTime() {
      return this.$store.state.user.oneTime;
    },
  },
  methods: {
    // TODO: Move to service
    // Call from WHERE?
    // How to feed this info?
    async checkCEP(payload) {
      let pureCep = payload.replaceAll("-", "");
      if (pureCep.length == 8) {
        /* this.$store.dispatch("loading", true); */
        await axios
          .get("https://viacep.com.br/ws/" + pureCep + "/json/")
          .then(async (response) => {
            if (response != undefined) {
              this.addressQuery.street = response.data.logradouro;
              this.addressQuery.complement = response.data.complemento;
              this.addressQuery.neighborhood = response.data.bairro;
              this.addressQuery.city = response.data.localidade;
              this.addressQuery.state = response.data.uf;
              this.addressQuery.cep = response.data.cep;
            }
          });
        /* this.$store.dispatch("loading", false); */
      }
    },
    async submit(payload) {
      let editMember = {
        name: payload.basic.name,
        contact: {
          phone: payload.basic.phone,
          email: payload.basic.email,
        },
        personal: {
          birthday: payload.personal.birthday,
          rg: payload.personal.rg,
          rgOrgan: payload.personal.rgOrgan,
          cpf: payload.personal.cpf,
          nameMother: payload.personal.nameMother,
          nameFather: payload.personal.nameFather,
          nationality: payload.personal.nationality,
        },
        work: {
          name: payload.work.name,
          phone: payload.work.phone,
        },
        address: {
          street: payload.address.street,
          complement: payload.address.complement,
          number: payload.address.number,
          neighborhood: payload.address.neighborhood,
          cep: payload.address.cep,
          city: payload.address.city,
          state: payload.address.state,
        },
      };

      if (payload.work.scolarship != undefined) {
        editMember.work.scolarship = payload.work.scolarship;
      }

      if (payload.personal.gender != undefined) {
        editMember.personal.gender = payload.personal.gender;
      }

      let updatedAcademy = this.$store.state.academy.currentAcademy;
      delete updatedAcademy.accessCodes[this.hash];
      await this.$store.dispatch("user/oneTimeUpdate", {
        id: this.oneTime.id,
        hash: this.hash,
        academy: updatedAcademy,
        member: editMember,
      });
    },

    cancelNewMember() {
      this.$router.push("/");
    },
  },
};
</script>

<template>
  <one-time-access-template
    v-if="oneTime != undefined && Object.keys(this.oneTime).length != 0"
    :member="oneTime"
    :academyId="id"
    :addressQuery="addressQuery"
    @checkCEP="checkCEP"
    @submit="submit"
  ></one-time-access-template>
</template>

<style lang="scss" scoped>
.container,
form {
  min-height: 100vh;
  min-width: 100%;
}
</style>
